import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Tooltip, TextField, FormControl, MenuItem, Typography, Autocomplete } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ActionButtons from 'components/Estoque/Entrada/ActionButtons';
import ActionButtonsCreate from 'components/Estoque/Entrada/ActionButtonsCreate';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import XmlData from './components/XmlData';
const { v4: uuidv4 } = require('uuid');
// ============================= INITIALSTATE =============================
const initialState = {
    documento: '',
    emissao: '',
    movimento: new Date(),
    fornecedor: 0,
    observacao: '',
    itens: []
};

const NovaEntrada = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const fileInputRef = useRef(null);
    const [xmlResponse, setXmlResponse] = useState(null);
    const [campos, setCampos] = useState(initialState);
    const [openCreate, setOpenCreate] = useState(false);
    const [itens, setItens] = useState([]);
    const [listAllItens, setListAllItens] = useState([]);
    const [listAllAlmoxarifado, setListAllAlmoxarifado] = useState([]);
    const [listAllLocalizacao, setListAllLocalizacao] = useState([]);
    const [listAllFornecedor, setListAllFornecedor] = useState([]);
    const [loading, setLoading] = useState(false);
    const columns = [
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 2 },
        { field: 'unidade', headerName: 'UN', flex: 2 },
        { field: 'quantidade', headerName: 'QNTD', flex: 0.5 },
        {
            field: 'valor_unitario',
            headerName: 'VALOR UN.',
            flex: 1,
            valueGetter: (params) => params.row.valor_unitario.toFixed(2)
        },
        {
            field: 'valor_total',
            headerName: 'VALOR T.',
            flex: 1,
            valueGetter: (params) => params.row.valor_total.toFixed(2)
        },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <ActionButtons
                    itens={itens}
                    setItens={setItens}
                    row={params.row}
                    atualizarCampoItem={atualizarCampoItem}
                    removerCampoItem={removerCampoItem}
                    listAllItens={listAllItens}
                    listAllAlmoxarifado={listAllAlmoxarifado}
                    listAllLocalizacao={listAllLocalizacao}
                />
            )
        }
    ];

    const somaValorTotal = itens.reduce((acumulador, objeto) => {
        return acumulador + Number(objeto.valor_total);
    }, 0);

    // ============================= FUNÇÕES =============================

    const atualizarCampo = (campo, valor) => {
        setCampos((prev) => ({ ...prev, [campo]: valor }));
    };

    const adicionarCampoItem = () => {
        setCampos((prevCampos) => ({
            ...prevCampos,
            itens: [
                ...prevCampos.itens,
                {
                    item_id: '',
                    descricao: '',
                    quantidade: 1,
                    unidade: '',
                    valor_unitario: 0,
                    valor_total: 0,
                    almoxarifado_id: '',
                    localizacao_id: '',
                    lote: '',
                    validade: '',
                    observacao: ''
                }
            ]
        }));
    };

    const removerCampoItem = (uuid) => {
        setItens((prevItens) => {
            const novosItens = [...prevItens];
            const filtredItens = novosItens.filter((item) => item.uuid != uuid);
            return filtredItens;
        });
        setCampos((prevCampos) => {
            const novosCampos = { ...prevCampos };
            novosCampos.itens.filter((item) => item.uuid != uuid);
            return novosCampos;
        });

        // fechar modal
        if (openCreate) {
            setOpenCreate(false);
        }
    };

    const atualizarCampoItem = async (uuid, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = { ...prevCampos };
            novosCampos.itens[campos?.itens.length - 1][campo] = valor;
            return novosCampos;
        });
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleClose = () => {
        setOpenCreate(false);
    };

    const handleOpenAndCreateCampoItem = () => {
        setOpenCreate(true);
        adicionarCampoItem();
    };

    const getAllData = async () => {
        try {
            const [responseAlmoxarifado, responseLocalizacao, responseItens, responseFornecedor] = await Promise.all([
                api.getAlmoxarifados(),
                api.getAllLocalizacoes(),
                api.getAllItems(),
                api.getAllFornecedor()
            ]);
            setListAllAlmoxarifado(responseAlmoxarifado.data);
            setListAllLocalizacao(responseLocalizacao.data);
            setListAllItens(responseItens.data);
            setListAllFornecedor(responseFornecedor.data);
        } catch (error) {
            toast.error(error?.message);
        }
    };

    const getDate = () => {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        let currentYear = date.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        setCampos((prev) => ({ ...prev, movimento: currentDate }));
    };

    // XML DATA FUNCTIONS
    const [openXmlModal, setOpenXmlModal] = useState(false);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files?.[0];

        if (file) {
            try {
                const formData = new FormData();
                formData.append('xml', file);
                const { data } = await api.sendXML(formData);
                setXmlResponse(data?.dadosXML);
                setOpenXmlModal(true);
            } catch (error) {
                toast.error(`Erro ao processar XML: ${error?.response?.data?.message ?? error.message}`);
            }
        }
    };

    const handleCloseXmlModal = () => {
        setXmlResponse(null);
        setOpenXmlModal(false);
    };

    //====== SUBMIT ======
    const handleSubmit = async (e, campos) => {
        e.preventDefault();
        itens.forEach((item) => {
            if (item.validade === '') {
                item.validade = null;
            }
            if (item.lote === '') {
                item.lote = null;
            }
            delete item.id;
        });
        const data = { entrada: campos, itens: itens };

        try {
            setLoading(true);
            await api.createEntradaEstoque(data.entrada, somaValorTotal, data.itens);
            toast.success('Entrada cadastrada');
            window.location.reload();
        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            toast.error(`Erro ao criar entrada: ${error.message}`);
        }
    };

    useEffect(() => {
        getAllData();
        getDate();
    }, []);

    return (
        <MainCard title="Nova Entrada">
            <Grid container spacing={gridSpacing}>
                {/* MODAL DE ADICIONAR ITEM */}
                <ActionButtonsCreate
                    open={openCreate}
                    handleClose={handleClose}
                    setItens={setItens}
                    campos={campos}
                    adicionarCampoItem={adicionarCampoItem}
                    atualizarCampoItem={atualizarCampoItem}
                    listAllItens={listAllItens}
                    listAllAlmoxarifado={listAllAlmoxarifado}
                    listAllLocalizacao={listAllLocalizacao}
                />

                {/* MODAL DE IMPORTAR E AJUSTAR OS DADOS DO XML */}
                <XmlData
                    open={openXmlModal}
                    handleClose={handleCloseXmlModal}
                    data={xmlResponse}
                    listItems={listAllItens}
                    listAlmoxarifados={listAllAlmoxarifado}
                    listLocalizacao={listAllLocalizacao}
                    setCampos={setCampos}
                    setItens={setItens}
                />

                <Box sx={{ width: '80%', margin: '0 auto' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginLeft: '1em', marginTop: '1em' }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
                            <Button variant="contained" onClick={handleButtonClick}>
                                Importar XML
                            </Button>
                            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} accept=".xml" />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '1em',
                                    width: '100%',
                                    marginBottom: '1em'
                                }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        type="text"
                                        label="Nota"
                                        value={campos.documento}
                                        onChange={(e) => atualizarCampo('documento', e.target.value)}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        type="date"
                                        label="Data emissão"
                                        value={campos.emissao}
                                        onChange={(e) => atualizarCampo('emissao', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        type="date"
                                        label="Movimento"
                                        value={campos.movimento}
                                        disabled
                                        onChange={(e) => atualizarCampo('movimento', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                                <FormControl sx={{ width: '200%' }}>
                                    <Autocomplete
                                        options={listAllFornecedor}
                                        getOptionLabel={(fornecedor) => `${fornecedor?.nome_fantasia} - ${fornecedor?.cnpj}`}
                                        value={listAllFornecedor.find((f) => f.id === campos.fornecedor) || null}
                                        onChange={(event, newValue) => {
                                            atualizarCampo('fornecedor', newValue ? newValue.id : '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Fornecedor" InputLabelProps={{ shrink: true }} />
                                        )}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl sx={{ width: '50%' }}>
                                    <TextField
                                        multiline
                                        minRows={3}
                                        label="Observação"
                                        placeholder="Observação"
                                        value={campos.observacao}
                                        onChange={(e) => atualizarCampo('observacao', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', marginTop: '2em', borderTop: '2px dashed #eee' }}>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
                                <Box>
                                    <Typography variant="h4">Relação de itens</Typography>
                                </Box>
                                <Box>
                                    <Tooltip title="Adicionar item">
                                        <Button variant="contained" color="success" onClick={(e) => handleOpenAndCreateCampoItem()}>
                                            <AddIcon />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>

                            <Box sx={{ marginTop: '2em', display: 'flex', flexDirection: 'column', height: '300px' }}>
                                <DataGrid
                                    rows={itens}
                                    columns={columns}
                                    hideFooter={true}
                                    onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                    getRowId={(row) => row.uuid}
                                />
                                <Box
                                    sx={{
                                        width: '40%',
                                        marginLeft: 'auto',
                                        marginTop: '1em',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <h4>Valor Total:</h4>
                                        <Box
                                            sx={{
                                                border: '1px solid #ccc',
                                                padding: '8px 16px',
                                                borderRadius: 2
                                            }}
                                        >
                                            {somaValorTotal.toFixed(2)}
                                        </Box>
                                    </Box>

                                    <Tooltip title="Salvar entrada">
                                        <Button
                                            variant="contained"
                                            color="success"
                                            disabled={loading}
                                            onClick={(e) => handleSubmit(e, campos)}
                                        >
                                            <SaveIcon />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default NovaEntrada;
