import { Card, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React from 'react';
import { Box } from '@mui/system';

const TempoMedio = ({ tempoMedio, name }) => {
    const converterParaMinutos = (tempo) => {
        const horasMatch = tempo.match(/(\d+)h/);
        const minutosMatch = tempo.match(/(\d+)m/);
        const segundosMatch = tempo.match(/(\d+)s/);

        const horas = horasMatch ? parseInt(horasMatch[1], 10) : 0;
        const minutos = minutosMatch ? parseInt(minutosMatch[1], 10) : 0;
        const segundos = segundosMatch ? parseInt(segundosMatch[1], 10) : 0;

        // Converte horas para minutos, e segundos para fração de minutos
        return horas * 60 + minutos + segundos / 60;
    };

    // Converte o tempoMedio para minutos
    const tempoEmMinutos = converterParaMinutos(tempoMedio);
    return (
        <Card
            sx={{
                background: tempoEmMinutos > 10 ? '#ff0000' : '#2196f3',
                color: '#fff',
                padding: '10px',
                minWidth: '200px',
                '&:hover svg': {
                    opacity: '1',
                    transform: 'scale(1.1)'
                },
                height: '55px',
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
                justifyContent: 'space-around'
            }}
        >
            <Box
                sx={{
                    color: '#fff',
                    marginLeft: '1rem',
                    '&> svg': {
                        width: 40,
                        height: 40,
                        opacity: '0.4',
                        transition: 'all .3s ease-in-out'
                    }
                }}
            >
                <AccessTimeIcon />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h2" color="inherit">
                    {tempoMedio}
                </Typography>

                <Typography variant="subtitle2" color="inherit">
                    {name}
                </Typography>
            </Box>
        </Card>
    );
};

export default TempoMedio;
