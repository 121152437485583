import { Box, Button, Grid, IconButton, Modal, TextField, Tooltip, Typography } from '@mui/material';
import { useApi } from 'Service/axios';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { formatarDataIdade } from 'utils/dataIdade';
import { MedicalRecordModal } from './components/MedicalRecordModal';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconLungs, IconArrowAutofitHeight, IconThermometer, IconEye, IconFileAnalytics, IconPercentage, IconPill } from '@tabler/icons';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import CloseIcon from '@mui/icons-material/Close';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import CalculateIcon from '@mui/icons-material/Calculate';
import { Atestado } from '../Atestado';
import MedicalPrescription from './components/MedicalPrescripotion';
import { Check, Print } from '@mui/icons-material';
import { IconClock } from '@tabler/icons-react';
import { DataGrid } from '@mui/x-data-grid';
import MedicalFinalizationModal from '../FinalizationModal';
import { PDFViewer } from '@react-pdf/renderer';
import PdfReceita from './components/PdfReceita';
import PdfAtestado from '../Atestado/component/PdfAtestado';
import { v4 } from 'uuid';
import formater from 'utils/DateDDMMYYY';
import ConjuntoLaudosPDF from './components/ConjuntoLaudos';
import { formatarData } from 'utils/formatarData';

export function MedicalRecord() {
    const { prontuarioId, pacienteId, name } = useParams();
    const api = useApi();
    const navigate = useNavigate();
    const consultorio = JSON.parse(localStorage.getItem('consultorio'));

    const [medicine, setMedicine] = useState({});
    const [triagem, setTriagem] = useState({});
    const [paciente, setPaciente] = useState({});
    const [medicalRecordObs, setMedicalRecordObs] = useState({});
    const [openAtestado, setOpenAtestado] = useState(false);
    const [openReceita, setOpenReceita] = useState(false);
    const [openFinalizationModal, setOpenFinalizationModal] = useState(false);
    const [openModalPdf, setOpenModalPdf] = useState(false);
    const [atestadoPdf, setAtestadoPdf] = useState(null);
    const [receitaPdf, setReceitaPdf] = useState(null);
    const [rows, setRows] = useState([]);
    const [rowsAtestadoReceita, setRowsAtestadoReceita] = useState([]);
    const [triggerReload, setTriggerReload] = useState(false);
    const [openModalLaudo, setOpenModalLaudo] = useState(false);

    const stylePdf = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        boxShadow: 24,
        height: '90vh'
    };

    function verificarStatus(item) {
        if (item.tipo === 'MEDICAMENTO' && item.atendimentos_enfermagems.every((atendimento) => atendimento.status === 'APLICADO')) {
            return (
                <Tooltip title="Finalizado">
                    <Check />
                </Tooltip>
            );
        } else if (
            item.tipo === 'LABORATÓRIO' &&
            item.atendimentos_enfermagems.every((atendimento) => atendimento.status === 'REALIZADO')
        ) {
            return (
                <Tooltip title="Finalizado">
                    <Check />
                </Tooltip>
            );
        } else if (item.tipo == 'RAIO-X' && item.item_conduta.finalizado) {
            return (
                <Tooltip title="Finalizado">
                    <Check />
                </Tooltip>
            );
        } else if (item.tipo == 'PROCEDIMENTO' && item.item_conduta.finalizado) {
            return (
                <Tooltip title="Finalizado">
                    <Check />
                </Tooltip>
            );
        }
        return (
            <Tooltip title="Aguardando">
                <IconClock />
            </Tooltip>
        );
    }

    const columns = [
        { field: 'cod', headerName: 'Código', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'qtd', headerName: 'Quantidade', flex: 0.5 },
        { field: 'unidade', headerName: 'Unidade', flex: 0.5 },
        {
            field: 'aplicacao',
            headerName: 'Aplicação',
            flex: 0.5,
            renderCell: (params) => <p style={{ textTransform: 'uppercase' }}>{params.row.aplicacao}</p>
        },
        { field: 'tipo', headerName: 'Tipo', flex: 0.5 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => verificarStatus(params.row)
        }
    ];

    const columnsLabRaioProcedimentos = [
        { field: 'cod', headerName: 'Código', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'tipo', headerName: 'Tipo', flex: 0.5 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => verificarStatus(params.row)
        }
    ];

    const columnsLab = [
        { field: 'cod', headerName: 'Código', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'tipo', headerName: 'Tipo', flex: 0.5 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            renderCell: (params) => verificarStatus(params.row)
        },
        {
            field: 'laudo',
            headerName: 'Laudo',
            flex: 0.4,
            renderCell: (params) => {
                const isSpecialExam = params.row?.item_conduta?.exame === null;

                // Definindo os ids com base na condição
                const exameId = !isSpecialExam ? params.row?.item_conduta?.exame?.id : null;
                const exameEspecialId = isSpecialExam ? params.row?.item_conduta?.exames_especiai?.id : null;

                return (
                    <Tooltip title="Visualizar laudo">
                        <IconButton
                            onClick={() => {
                                // Garante que pelo menos um dos dois IDs tenha um valor
                                if (exameId !== null) {
                                    getLaudoData([
                                        {
                                            idExameProntuario: params?.row?.item_conduta?.id,
                                            exameId: exameId,
                                            exameEspecialId: exameEspecialId,
                                            prontuarioId: params.row.prontuario.id,
                                            especial: isSpecialExam
                                        }
                                    ]);
                                }

                                if (exameEspecialId !== null) {
                                    return window.open(
                                        `/laboratorio/resultado-especial/${params.row.prontuario.id}/${exameEspecialId}/${params?.row?.item_conduta?.id}`
                                    );
                                }
                            }}
                        >
                            <Print />
                        </IconButton>
                    </Tooltip>
                );
            }
        }
    ];

    const columnsAtestadoReceita = [
        { field: 'id', headerName: 'ID DOC', flex: 1 },
        { field: 'tipo', headerName: 'TIPO', flex: 1 },
        { field: 'medico', headerName: 'MEDICO', flex: 1 },
        {
            field: 'acoes',
            headerName: 'AÇÕES',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Imprimir">
                    <Button
                        onClick={() => {
                            params.row.tipo == 'ATESTADO' ? getAtestadoData(params.row.id) : getReceitaData(params.row.id);
                        }}
                    >
                        <Print />
                    </Button>
                </Tooltip>
            )
        }
    ];

    async function getAtestadoData(id) {
        try {
            console.log('🚀 ~ MedicalRecord ~ params.row:', params.row);
            const { data } = await api.getAtestadoById(id);
            const pdfData = {
                dias: data.dias,
                observation: data?.observacao,
                nome: data?.paciente?.nome_completo,
                nascimento: formater(data?.paciente?.data_nascimento),
                data: triagem?.data
            };
            setAtestadoPdf(pdfData);
            setOpenModalPdf(true);
        } catch (error) {
            toast.error(`Erro ao buscar dados do atestado: ${error.message}`);
        }
    }

    async function getReceitaData(id) {
        try {
            const { data } = await api.getReceitaById(id);
            setReceitaPdf(data);
            setOpenModalPdf(true);
        } catch (error) {
            toast.error(`Erro ao buscar dados do atestado: ${error.message}`);
        }
    }

    async function getRecord() {
        try {
            const { data } = await api.getMedicalRecord(prontuarioId, pacienteId);
            setMedicine(data?.medicacao);
            setTriagem(data?.triagem);
            const res = await api.getPacienteById(pacienteId);
            setPaciente(res.data);
            handleMedicalRecord();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    const getConduct = async () => {
        try {
            const { data } = await api.getMedicalConduct(prontuarioId);
            const row = data.map((item) => {
                let descricao;
                let qtd = '-';
                let unidade = '-';
                let aplicacao = '-';

                if (item?.tipo === 'LABORATÓRIO') {
                    if (item.item_conduta.exame) {
                        descricao = item?.item_conduta.exame?.nome;
                    } else {
                        descricao = item?.item_conduta.exames_especiai?.nome;
                    }
                } else if (item?.tipo === 'RAIO-X') {
                    descricao = item?.item_conduta.tipos_raio_x?.descricao;
                } else if (item?.tipo === 'MEDICAMENTO') {
                    let medInfo = item?.item_conduta?.item_pedido;

                    descricao = medInfo.descricao;
                    qtd = item?.item_conduta?.quantidade_item;
                    unidade = medInfo.unidade.sigla;
                    aplicacao = item?.item_conduta.via_aplicacao;
                } else if (item?.tipo === 'PROCEDIMENTO') {
                    descricao = item?.item_conduta?.procedimento?.descricao;
                }

                return {
                    ...item,
                    cod: item.id,
                    descricao: descricao,
                    qtd,
                    unidade,
                    aplicacao
                };
            });
            setRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    async function handleMedicalRecord() {
        try {
            const { data } = await api.getMedicalRecordObservation(prontuarioId);
            setMedicalRecordObs(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    function handleBackPage() {
        navigate('/main/consultorio/atendimento');
    }

    function handleCloseAtestado() {
        setOpenAtestado(false);
    }

    function handleCloseReceita() {
        setOpenReceita(false);
    }

    async function getAtestadoReceita() {
        try {
            const { data } = await api.getAtestadoReceitaByProntuario(prontuarioId);
            const rows = data.map((item) => ({
                ...item,
                uuid: v4(),
                tipo: item?.dias ? 'ATESTADO' : 'RECEITA',
                medico: item?.usuario?.nome
            }));

            setRowsAtestadoReceita(rows);
        } catch (error) {
            toast.error('Ocorreu um erro ao buscar os dados.');
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                await Promise.all([getRecord(), getConduct(), getAtestadoReceita()]);
            } catch (error) {
                toast.error('Erro ao buscar dados da ficha:', error);
            }
        }

        fetchData();
    }, [triggerReload]);

    const [data, setData] = useState({});

    const getLaudoData = async (laudoPayload) => {
        const toastId = toast.loading('Buscando laudos...');
        try {
            const response = await api?.getResultsForLaudos(laudoPayload);
            console.log('🚀 ~ getLaudoData ~ response:', response);

            const agruparDados = response?.data.map((el) => {
                const resultadoCampoEspecifico = el.resultado_campo_especifico;
                const data = formatarData(el?.medico?.data_execucao);

                // Use o método reduce para agrupar os campos por categoria
                const camposAgrupados = resultadoCampoEspecifico.reduce((agrupados, item) => {
                    const categoria = item.campos_especifico.categoria;

                    // Se a categoria já existir no objeto agrupados, adicione o item a ela
                    if (agrupados[categoria]) {
                        agrupados[categoria].push(item);
                    } else {
                        // Se a categoria ainda não existir, crie um novo array com o item
                        agrupados[categoria] = [item];
                    }

                    return agrupados;
                }, {});
                for (const categoria in camposAgrupados) {
                    camposAgrupados[categoria].sort((a, b) => a.id - b.id);
                }
                return {
                    ...el,
                    data,
                    camposAgrupados
                };
            });

            const ordenarExames = (a, b) => {
                const aNome = a?.exame?.nome?.toLowerCase();
                const bNome = b?.exame?.nome?.toLowerCase();

                if (aNome?.includes('hemograma') || aNome?.includes('urina')) {
                    return -1;
                } else if (bNome?.includes('hemograma') || bNome?.includes('urina')) {
                    return 1;
                }

                return 0;
            };

            response?.data?.sort(ordenarExames);

            const parsedData = laudoPayload?.map(async (item) => {
                let observacao = await api.getObservation(
                    item.prontuarioId,
                    item.especial ? null : item.exameId,
                    item.especial ? item.exameId : null,
                    item.idExameProntuario
                );
                return observacao;
            });

            agruparDados.map((item) => ({ ...item, parsedData }));
            setOpenModalLaudo(true);
            setData(response.data);
            toast.update(toastId, { render: 'Laudos carregados com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
            // AQUI
        } catch (error) {
            console.log('🚀 ~ getLaudoData ~ error:', error);
            toast.update(toastId, { render: 'Erro ao buscar laudos', type: 'error', isLoading: false, autoClose: 3000 });
        }
    };

    return (
        <MainCard title={`${consultorio?.descricao} - ${name}`}>
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Modal
                    open={openModalLaudo}
                    onClose={() => setOpenModalLaudo(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={stylePdf}>
                        <PDFViewer width={'100%'} height={'100%'}>
                            <ConjuntoLaudosPDF data={data} />
                        </PDFViewer>
                    </Box>
                </Modal>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', position: 'absolute', right: 0, top: '-60px' }}>
                        <p>
                            PRONTUÁRIO: <b>{triagem?.prontuario_id}</b>
                        </p>
                        <p>
                            FICHA DE ATENDIMENTO: <b> {medicine?.paciente_id}</b>
                        </p>
                        <p>
                            DATA: <b> {triagem?.data}</b>
                        </p>
                        <p>
                            IDADE: <b> {formatarDataIdade(paciente?.data_nascimento)}</b>
                        </p>
                    </Box>
                    <Box sx={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', padding: '10px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h4>ESCUTA INICIAL</h4>
                            <p>
                                HORA: <b>{triagem?.hora}</b>
                            </p>

                            <h4>
                                RESPONSÁVEL: <b>{triagem?.usuario?.nome}</b>
                            </h4>
                        </Box>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MonitorHeartIcon />
                                <TextField
                                    label="SIS"
                                    type="number"
                                    value={triagem?.pa_1}
                                    name="pa_sis"
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px', marginLeft: '.5rem' }}
                                />
                                <CloseIcon sx={{ margin: '0 5px' }} />
                                <TextField
                                    label="DIA"
                                    type="number"
                                    placeholder="DIA"
                                    value={triagem?.pa_2}
                                    name="pa_dia"
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px' }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FavoriteIcon />
                                <TextField
                                    label="FC"
                                    type="number"
                                    value={triagem?.fc}
                                    name="fc"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconLungs />
                                <TextField
                                    label="FR"
                                    type="number"
                                    value={triagem?.fr}
                                    name="fc"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <VaccinesIcon />
                                <TextField
                                    label="DEXTRO"
                                    type="number"
                                    value={triagem?.dextro}
                                    name="fc"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MonitorWeightIcon />
                                <TextField
                                    disabled
                                    label="Peso"
                                    name="peso"
                                    type="text"
                                    value={triagem.peso}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px', marginLeft: '.5rem' }}
                                />
                                <IconArrowAutofitHeight sx={{ margin: '0 5px' }} />
                                <TextField
                                    disabled
                                    label="Altura"
                                    name="altura"
                                    type="text"
                                    value={triagem.altura}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px', marginLeft: '.5rem' }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CalculateIcon />
                                <TextField
                                    label="IMC"
                                    name="IMC"
                                    type="number"
                                    value={triagem.imc}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconThermometer />
                                <TextField
                                    label="Temperatura"
                                    name="temperatura"
                                    type="text"
                                    value={triagem?.temperatura}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconPercentage />
                                <TextField
                                    label="Saturação"
                                    name="saturacao"
                                    type="number"
                                    value={triagem?.saturacao}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ marginTop: '10px' }}>
                            QUEIXA: <b>{triagem?.queixa ? triagem?.queixa : 'QUEIXA NÃO INFORMADA'}</b>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p>
                                MÉDICAÇÃO EM USO:{' '}
                                {medicine?.medicacao_em_uso?.length === 0
                                    ? 'NEGA'
                                    : medicine?.medicacao_em_uso?.map((item, index, array) => (
                                          <span key={index}>{array.length - 1 === index ? <b>{item}</b> : <b>{item} |</b>} </span>
                                      ))}
                            </p>
                            <p>
                                ALERGIA:{' '}
                                {medicine?.alergia?.length === 0
                                    ? 'NEGA'
                                    : medicine?.alergia?.map((item, index, array) => (
                                          <span key={index}>{array.length - 1 === index ? <b>{item}</b> : <b>{item} |</b>} </span>
                                      ))}
                            </p>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ borderRadius: '7px', border: '1px solid #ccc', padding: '10px', marginTop: '20px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h3>Ficha médica</h3>
                        <MedicalRecordModal data={medicalRecordObs} paciente={paciente} prontuarioId={prontuarioId} getRecord={getRecord} />
                    </Box>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        label="Observação médica"
                        disabled
                        fullWidth
                        multiline
                        rows={5}
                        value={medicalRecordObs?.conteudo}
                    />
                </Box>

                <Box sx={{ borderRadius: '7px', border: '1px solid #ccc', padding: '10px', marginTop: '20px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h3>CONDUTA MÉDICA</h3>
                        <Button variant="contained" onClick={() => navigate(`/main/consultorio/conduta/${prontuarioId}/${name}`)}>
                            Adicionar
                        </Button>
                    </Box>
                    <Box sx={{ marginTop: '20px' }}>
                        {rows.length === 0 ? (
                            <p>Nenhum dado encontrado</p>
                        ) : (
                            <>
                                {rows.filter((item) => item.tipo === 'MEDICAMENTO').length > 0 && (
                                    <>
                                        <h3>MEDICAMENMTOS</h3>
                                        <DataGrid
                                            sx={{ maxHeight: '400px' }}
                                            columns={columns}
                                            rows={rows.filter((item) => item.tipo === 'MEDICAMENTO')}
                                            hideFooter
                                        />
                                    </>
                                )}
                                {rows.filter((item) => item.tipo === 'LABORATÓRIO').length > 0 && (
                                    <>
                                        <h3>LABORATÓRIO</h3>
                                        <DataGrid
                                            sx={{ maxHeight: '400px' }}
                                            columns={columnsLab}
                                            rows={rows.filter((item) => item.tipo === 'LABORATÓRIO')}
                                            hideFooter
                                        />
                                    </>
                                )}
                                {rows.filter((item) => item.tipo === 'RAIO-X' || item.tipo === 'PROCEDIMENTO').length > 0 && (
                                    <>
                                        <h3>RAIO-X / PROCEDIMENTOS</h3>
                                        <DataGrid
                                            sx={{ maxHeight: '400px' }}
                                            columns={columnsLabRaioProcedimentos}
                                            rows={rows.filter((item) => item.tipo === 'RAIO-X' || item.tipo === 'PROCEDIMENTO')}
                                            hideFooter
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
                <Box
                    sx={{
                        padding: '10px',
                        marginTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        borderRadius: '7px',
                        border: '1px solid #ccc'
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3>Atestados e Receitas</h3>
                        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <Tooltip title="Receita" onClick={() => setOpenReceita(true)}>
                                <MedicalPrescription paciente={paciente} triagem={triagem} setTriggerReload={setTriggerReload} />
                            </Tooltip>
                            <Tooltip title="Atestado">
                                <Button variant="contained" onClick={() => setOpenAtestado(true)}>
                                    <IconFileAnalytics />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>

                    {rowsAtestadoReceita.length > 0 && (
                        <DataGrid columns={columnsAtestadoReceita} rows={rowsAtestadoReceita} getRowId={(row) => row?.uuid} />
                    )}
                    <Modal
                        open={openModalPdf}
                        onClose={() => {
                            setAtestadoPdf(null);
                            setReceitaPdf(null);
                            setOpenModalPdf(false);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={stylePdf}>
                            {receitaPdf && (
                                <PDFViewer width={'100%'} height={'100%'}>
                                    <PdfReceita data={receitaPdf} />
                                </PDFViewer>
                            )}
                            {atestadoPdf && (
                                <PDFViewer width={'100%'} height={'100%'}>
                                    <PdfAtestado data={atestadoPdf} />
                                </PDFViewer>
                            )}
                        </Box>
                    </Modal>
                </Box>
                <Box sx={{ width: '100%', marginTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" color="error" onClick={handleBackPage}>
                        Voltar
                    </Button>
                    <Button variant="contained" onClick={() => setOpenFinalizationModal(true)}>
                        Finalizar Conduta
                    </Button>
                </Box>
                <MedicalFinalizationModal
                    open={openFinalizationModal}
                    setOpenFinalizationModal={setOpenFinalizationModal}
                    prontuarioId={prontuarioId}
                />
                <Atestado
                    open={openAtestado}
                    handleClose={handleCloseAtestado}
                    paciente={paciente}
                    data={triagem?.data}
                    prontuarioId={prontuarioId}
                    setTriggerReload={setTriggerReload}
                />
            </Grid>
        </MainCard>
    );
}
