import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Aguardando from 'components/Triagem/Cards/Aguardando';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import TabelaPainelTriagem from './components/Tabela';
import { useContext } from 'react';
import CardPrimary from './components/Cards/CardPrimary';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardHora from './components/Cards/CardHora';
import { useApi } from 'Service/axios';

const DoctorPainel = () => {
    const api = useApi();
    // --- STATES --- \\
    const [dataAtual, setDataAtual] = useState(new Date());
    const [waitTime, setWaitTime] = useState('');
    const [waitTimeLab, setWaitTimeLab] = useState('');
    const [quantidade, setQuantidade] = useState(0);
    const [quantidadeLab, setQuantidadeLab] = useState(0);
    const [limiteTempo, setLimiteTempo] = useState(false);
    const [row, setRow] = useState([]);
    const [rowsLab, setRowsLab] = useState([]);
    // --- FUNCTIONS --- \\
    const formatarNumero = (numero) => {
        return numero < 10 ? `0${numero}` : numero;
    };

    const dia = formatarNumero(dataAtual.getDate());
    const mes = formatarNumero(dataAtual.getMonth() + 1);
    const ano = dataAtual.getFullYear();

    // ----EFFECTS --- //

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDataAtual(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (row.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(row[0]?.data_atendimento);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                const waitTimeMoreThan10Minutes = waitTimeInSeconds > 600; // 600 seconds = 10 minutes
                setLimiteTempo(waitTimeMoreThan10Minutes);

                setWaitTime(`${hours}h ${minutes}m ${seconds}s`);

                // Use a variável waitTimeMoreThan10Minutes conforme necessário
            };
            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }

        setQuantidade(row.length);
    }, [row]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDataAtual(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (rowsLab.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(rowsLab[0]?.data_atendimento);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                const waitTimeMoreThan10Minutes = waitTimeInSeconds > 600; // 600 seconds = 10 minutes
                setLimiteTempo(waitTimeMoreThan10Minutes);

                setWaitTimeLab(`${hours}h ${minutes}m ${seconds}s`);

                // Use a variável waitTimeMoreThan10Minutes conforme necessário
            };
            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }

        setQuantidadeLab(rowsLab.length);
    }, [rowsLab]);

    async function getTableLab() {
        try {
            const { data } = await api.getPatientsByClinic();
            const row = data
                .filter((element) => element.status == 'MEDICACAO')
                .map((item) => ({
                    ...item,
                    ficha: item?.id,
                    fa: item?.paciente?.id,
                    idade: item?.paciente?.idade,
                    data: item.data,
                    data_atendimento: item?.data_atendimento,
                    hora: item?.hora_atendimento?.slice(0, 5),
                    status: item?.status,
                    paciente: item?.paciente?.nome_completo,
                    enfermagem: ' - ',
                    lab: item?.todasCondutasFinalizadasPorTipo?.laboratorio ? 'Finalizado' : 'Pendente',
                    raiox: item?.todasCondutasFinalizadasPorTipo?.raiox ? 'Finalizado' : 'Pendente',
                    autismo: item?.paciente?.autismo
                }));
            setRowsLab(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getTable() {
        try {
            const { data } = await api.getPatientsByClinic();
            const row = data
                .filter((element) => element.status == 'CONSULTORIO' || element.status == 'TRIAGEM')
                .map((item) => ({
                    ...item,
                    ficha: item?.id,
                    fa: item?.paciente?.id,
                    idade: item?.paciente?.idade,
                    data: item.data,
                    data_atendimento: item?.data_atendimento,
                    hora: item?.hora_atendimento?.slice(0, 5),
                    status: item?.status,
                    paciente: item?.paciente?.nome_completo,
                    enfermagem: ' - ',
                    lab: item?.todasCondutasFinalizadasPorTipo?.laboratorio ? 'Finalizado' : 'Pendente',
                    raiox: item?.todasCondutasFinalizadasPorTipo?.raiox ? 'Finalizado' : 'Pendente',
                    autismo: item?.paciente?.autismo
                }));
            setRow(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        let elemento = document.documentElement;
        elemento.requestFullscreen();
    }, []);

    useEffect(() => {
        const getData = () => {
            getTable();
            getTableLab();
        };
        getData();
        getTableLab();
        const interval = setInterval(getData, 10000);
        return () => clearInterval(interval);
    }, []);
    return (
        <Box sx={{ width: '100%', marginTop: '1rem' }}>
            <Box
                sx={{
                    width: '90%',
                    display: 'flex',
                    margin: '0 auto',
                    gap: '1rem',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h1" sx={{ textAlign: 'center' }}>
                    PAINEL CONSULTÓRIO MÉDICO
                </Typography>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Typography variant="h3">
                        DATA: {dia}/{mes}/{ano}
                    </Typography>
                    <Typography variant="h3">
                        HORA: {formatarNumero(dataAtual.getHours())}:{formatarNumero(dataAtual.getMinutes())}:
                        {formatarNumero(dataAtual.getSeconds())}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '50%' }}>
                    <Box
                        sx={{
                            width: '90%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '3rem',
                            margin: '2rem auto'
                        }}
                    >
                        <CardPrimary data={row.length} legenda={'Aguardando'} icon={<PersonIcon />} size={'5rem'} quantidade={quantidade} />
                        <CardHora
                            data={waitTime}
                            legenda={'Tempo Espera'}
                            icon={<AccessTimeIcon />}
                            size={'3.2rem'}
                            limiteTempo={limiteTempo}
                        />
                    </Box>

                    <Divider sx={{ width: '90%', margin: '0 auto' }} />

                    <Box sx={{ width: '90%', margin: '0 auto', height: '500px' }}>
                        <TabelaPainelTriagem atendimentos={row} />
                    </Box>
                </Box>
                <Box sx={{ width: '50%' }}>
                    <Box
                        sx={{
                            width: '90%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '3rem',
                            margin: '2rem auto'
                        }}
                    >
                        <CardPrimary
                            data={rowsLab.length}
                            legenda={'Aguardando'}
                            icon={<PersonIcon />}
                            size={'5rem'}
                            quantidade={quantidade}
                        />
                        <CardHora
                            data={waitTimeLab}
                            legenda={'Tempo Espera'}
                            icon={<AccessTimeIcon />}
                            size={'3.2rem'}
                            limiteTempo={limiteTempo}
                        />
                    </Box>

                    <Divider sx={{ width: '90%', margin: '0 auto' }} />

                    <Box sx={{ width: '90%', margin: '0 auto', height: '500px' }}>
                        <TabelaPainelTriagem atendimentos={rowsLab} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default DoctorPainel;
