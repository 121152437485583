import { Box, Divider } from '@mui/material';
import { IconListDetails, IconStethoscope, IconUsers, IconVaccine, IconVaccineBottle } from '@tabler/icons';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import UserCountCard from 'ui-component/UserCountCard';

const NursingCards = ({ medicamentoLenght, laboratorioLenght, procedimentosLenght, rayxLenght, totalAtendimentos }) => {
    return (
        <Box sx={{ padding: '0 1rem' }}>
            <Box>
                <h1 style={{ textAlign: 'center' }}>PAINEL DE ENFERMAGEM</h1>
                <Divider />
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // Mantém 2 colunas
                    gap: '1rem',
                    marginTop: '2rem',
                    '& > :nth-child(5)': {
                        gridColumn: '1 / span 2', // O quinto item ocupa as 2 colunas
                        justifySelf: 'center', // Centraliza o item horizontalmente
                        width: '50%', // Garante que o item ocupe toda a largura disponível
                        textAlign: 'center' // Centraliza o conteúdo dentro do card
                    }
                }}
            >
                <UserCountCard color={'#2196f3'} primary={totalAtendimentos} secondary={'ATENDIMENTOS'} iconPrimary={IconUsers} />
                <UserCountCard color={'#2196f3'} primary={medicamentoLenght} secondary={'MEDICAÇÕES'} iconPrimary={IconVaccineBottle} />
                <UserCountCard color={'#2196f3'} primary={laboratorioLenght} secondary={'LABORATÓRIO'} iconPrimary={IconStethoscope} />
                <UserCountCard color={'#2196f3'} primary={procedimentosLenght} secondary={'PROCEDIMENTOS'} iconPrimary={IconListDetails} />
                <UserCountCard color={'#2196f3'} primary={rayxLenght} secondary={'RAIO-X'} iconPrimary={IconListDetails} />
            </Box>
        </Box>
    );
};

export default NursingCards;
