import axios from 'axios';
import { toast } from 'react-toastify';

const azureAPI = process.env.REACT_APP_api_azure;

// LOCALHOST
// const api = axios.create({
//     baseURL: 'http://localhost:8080'
// });

// RAILWAY dev
// const api = axios.create({
//     baseURL: 'https://resourceful-tenderness-production.up.railway.app'
// });

// RAILWAY HOMOLOGAÇÃO
// const api = axios.create({
//     baseURL: 'https://santacasaback-production.up.railway.app'
// });

/////////////////////////// !! PRODUÇÃO !! \\\\\\\\\\\\\\\\\\\\\\\\\\\\
const api = axios.create({
    baseURL: azureAPI
});
//////////////////////////////////\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

const token = JSON.parse(localStorage.getItem('token'));
const headers = {
    Authorization: `Bearer ${token}`
};

function removerPontuacao(numero) {
    // Utiliza expressão regular para remover vírgulas e pontos do número
    return numero.toString().replace(/[,.]/g, '');
}

export const useApi = () => ({
    createUser: async (values) => {
        const response = await api.post('/usuarios', {
            nome: values.nome.toUpperCase(),
            cpf: values.cpf,
            telefone: values.telefone,
            setor: values.setor,
            email: values.email.toUpperCase(),
            senha: values.password,
            cbo: values.cbo,
            cns: values.cns,
            role: values.role,
            medicDetails: values.medicDetails
        });
        return response;
    },
    createMedico: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/usuarios/user-medico',
            {
                nome: values.nome.toUpperCase() || null,
                cpf: values.cpf || null,
                telefone: values.telefone || null,
                mae_pai: values.nome_pais.toUpperCase() || null,
                data_nascimento: values.dn || null,
                rg: values.rg || null,
                crm: values.crm_coren || null,
                cep: values.cep || null,
                cidade: values.cidade.toUpperCase() || null,
                endereco_completo: values.endereco_completo.toUpperCase() || null,
                especialidade: values.especialidade.toUpperCase() || null,
                naturalidade: values.naturalidade.toUpperCase() || null,
                cns: values.cns || null
            },
            { headers }
        );
        return response;
    },

    // LOGIN
    signin: async (values) => {
        const response = await api.post('/usuarios/login', {
            cpf: values.cpf,
            senha: values.password
        });

        return response;
    },

    //    USUÁRIOS
    getAllUsers: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/usuarios/listar-usuarios', { headers });
        return response;
    },

    getUserById: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/usuarios/listar-usuarios/${id}`, { headers });
        return response;
    },

    updateUser: async (values, id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/usuarios/editar-usuario/${id}`,
            {
                nome: values.nome.toUpperCase(),
                cpf: values.cpf,
                telefone: values.telefone,
                setor: values.setor,
                email: values.email.toUpperCase(),
                senha: values.password,
                cbo: values.cbo,
                cns: values.cns,
                role: values.role,
                medicDetails: values.info_medico
            },
            {
                headers
            }
        );
        return response;
    },

    deleteUser: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/usuarios/deletar-usuario/${id}`, { headers });
        return response;
    },

    // API DE CEP
    getCepLocation: async (cod) => {
        const response = await api.get(`https://viacep.com.br/ws/${cod}/json/`);
        return response;
    },
    // API CODIGO IBGE
    getIbgeLocation: async (cod) => {
        const response = await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${cod}/distritos`);
        return response;
    },

    // PRONTUARIO
    createPaciente: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.post(
            '/pacientes',
            {
                nome_completo: values.nome.toUpperCase(),
                nome_social: values.nome_social.toUpperCase(),
                sexo: values.sexo === null ? null : values.sexo === 'outros' ? 3 : values.sexo === 'masculino' ? 1 : 2,
                cor: values.cor !== '' ? values.cor.toUpperCase() : null,
                estado_civil: values.estado_civil !== '' ? values.estado_civil.toUpperCase() : null,
                data_nascimento: values.data_nascimento !== '' && values.data_nascimento !== '' ? values.data_nascimento : null,
                cpf: values.cpf !== '' ? values.cpf : null,
                rg: values.rg !== '' ? values.rg : null,
                nome_mae: values.nome_mae !== '' ? values.nome_mae.toUpperCase() : null,
                nome_pai: values.nome_pai !== '' ? values.nome_pai.toUpperCase() : null,
                cartao_sus: values.cartao_sus,
                numero_carteirinha: values.numero_carterinha !== '' ? values.numero_carterinha : null,
                naturalidade: values.naturalidade.toUpperCase(),
                obito: values.obito,
                convenio_id: values.convenio !== '' ? parseInt(values.convenio) : null,
                cep: values.cep !== '' ? values.cep : null,
                tipo_logradouro: values.tipo_logradouro !== '' ? values.tipo_logradouro.toUpperCase() : null,
                endereco: values.endereco !== '' ? values.endereco.toUpperCase() : null,
                numero: values.numero !== '' ? String(values.numero) : null,
                bairro: values.bairro !== '' ? values.bairro.toUpperCase() : null,
                cidade: values.cidade !== '' ? values.cidade.toUpperCase() : null,
                ibge: values.ibge !== '' ? values.ibge : null,
                estado: values.estado !== '' ? values.estado.toUpperCase() : null,
                telefone: values.telefone !== '' ? values.telefone : null,
                telefone_secundario: values.telefone_secundario !== '' ? values.telefone_secundario : null,
                autismo: values.autismo !== '' ? values.autismo : null
            },
            {
                headers
            }
        );
        return response;
    },
    createAtendimentoExterno: async (value) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/prontuarios/procedimento-externo',
            {
                id_paciente: value.id_paciente,
                id_tipo_consulta: null,
                id_medico: null,
                data_atendimento: value.data_atendimento,
                hora_atendimento: value.hora_atendimento,
                procedimento_externo: true,
                procedimentos: value.procedimentos
            },
            { headers }
        );
        return response;
    },

    getAllPaciente: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/pacientes', { headers });
        return response;
    },
    getPacienteByNome: async (value) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/pacientes/verificar/nome?nome=${value}`);
        return response;
    },
    getPacienteBySUS: async (value) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/pacientes/verificar/sus?sus=${value}`);
        return response;
    },
    getPacienteById: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/pacientes/${id}`, { headers });
        return response;
    },
    updatePaciente: async (id, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/pacientes/${id}`,
            {
                nome_completo: values.nome.toUpperCase(),
                nome_social: values.nome_social.toUpperCase(),
                sexo: values.sexo === '' ? null : values.sexo === 'OUTROS' ? 3 : values.sexo === 'MASCULINO' ? 1 : 2,
                cor: values.cor !== null ? values.cor.toUpperCase() : null,
                estado_civil: values.estado_civil !== null ? values.estado_civil.toUpperCase() : null,
                data_nascimento: values.data_nascimento !== '' ? values.data_nascimento : null,
                cpf: values.cpf !== null ? values.cpf : null,
                rg: values.rg !== null ? values.rg : null,
                nome_mae: values.nome_mae !== null ? values.nome_mae.toUpperCase() : null,
                nome_pai: values.nome_pai !== null ? values.nome_pai.toUpperCase() : null,
                cartao_sus: values.cartao_sus,
                numero_carteirinha: values.numero_carterinha !== null ? values.numero_carterinha : null,
                naturalidade: values.naturalidade.toUpperCase(),
                obito: values.obito === '1' ? true : false,
                convenio_id: values.convenio !== null ? parseInt(values.convenio) : null,
                cep: values.cep !== null ? values.cep : null,
                tipo_logradouro: values.tipo_logradouro !== null ? values.tipo_logradouro.toUpperCase() : null,
                endereco: values.endereco !== null ? values.endereco.toUpperCase() : null,
                numero: values.numero !== null ? String(values.numero) : null,
                bairro: values.bairro !== null ? values.bairro.toUpperCase() : null,
                cidade: values.cidade !== null ? values.cidade.toUpperCase() : null,
                ibge: values.ibge !== null ? values.ibge : null,
                estado: values.estado !== null ? values.estado.toUpperCase() : null,
                telefone: values.telefone !== null ? values.telefone : null,
                telefone_secundario: values.telefone_secundario !== '' ? values.telefone_secundario : null,
                cadastro_ativo: values.cadastro_ativo !== null ? values.cadastro_ativo : null,
                autismo: values.autismo !== null ? values.autismo : null
            },
            {
                headers
            }
        );
        return response;
    },
    deletePaciente: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/pacientes/${id}`, { headers });
        return response;
    },

    //========== PRNTUARIO / PACIENTE =========
    getProntuarioByPaciente: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/prontuarios/pacientes/${id}/prontuarios`, { headers });
        return response;
    },

    getProntuarioById: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/prontuarios/${id}`, { headers });
        return response;
    },

    getAllProntuario: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/prontuarios', { headers });
        return response;
    },
    getAllProntuariosIds: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/prontuarios/all-ids', { headers });
        return response;
    },
    updateStatusProntuarioAusente: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/prontuarios/${id}`, { headers });
        return response;
    },
    // CONVENIO
    getAllConvenio: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('convenios', {
            headers
        });
        return response;
    },

    createProntuario: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.post(
            '/prontuarios',
            {
                id_paciente: values.id_paciente,
                id_medico: values.id_medico,
                id_tipo_consulta: values.id_tipo_consulta,
                data_atendimento: values.data_atendimento,
                hora_atendimento: values.hora_atendimento,
                id_status: '1',
                interno: values.interno,
                recepcionista_id: usuario
            },
            { headers }
        );
        return response;
    },

    getTipoConsulta: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/tipo-consulta', { headers });
        return response;
    },

    //========== CHAMADAS =========
    getAllChamadas: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
    },
    createNovoAtendimento: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/numero-atendimento', { headers });
        return response;
    },
    getTipoConsultaById: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/tipo-consulta/${id}`, { headers });
        return response;
    },

    getAllPacienteRecepcao: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/pacientes-recepcao', { headers });
        return response;
    },
    // ================== TRIAGEM ==================
    createTriagem: async (id, values) => {
        const token = JSON.parse(localStorage.getItem('token'));

        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/preencher-triagem',
            {
                id_prontuario: parseInt(id),
                pa: values.pa,
                fc: parseInt(values.fc),
                fr: parseInt(values.fr),
                dextro: parseInt(values.dextro),
                peso: parseInt(values.peso),
                altura: parseInt(values.altura),
                temperatura: parseInt(values.temperatura),
                saturacao: parseInt(values.saturacao),
                medicacao_em_uso: values.medicacao_em_uso,
                alergia: values.alergia,
                queixa: values.queixa.toUpperCase() || null
            },
            { headers }
        );
        return response;
    },

    getTriagem: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = { Authorization: `Bearer ${token}` };
        const response = await api.get('/prontuarios/atendimentos-triagem', { headers });
        return response;
    },
    getTriagemConsulta: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = { Authorization: `Bearer ${token}` };
        const response = await api.get('triagem', { headers });
        return response;
    },
    getTriagemConsultaById: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = { Authorization: `Bearer ${token}` };
        const response = await api.get(`/triagem/${id}`, { headers });
        return response;
    },
    getTriagemById: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = { Authorization: `Bearer ${token}` };
        const response = await api.get(`/triagem/${values.ficha}/${values.id}`, { headers });
        return response;
    },
    createPreencherTriagem: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const usuario = localStorage.getItem('id');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await api.post(
            `/triagem/${Number(values?.id_prontuario)}`,
            {
                pa_1: values.pa_sis.length > 0 ? Number(values.pa_sis) : null,
                pa_2: values.pa_dia.length > 0 ? Number(values.pa_dia) : null,
                fc: values.fc.length > 0 ? Number(values.fc) : null,
                fr: values.fr.length > 0 ? Number(values.fr) : null,
                dextro: values.dextro.length > 0 ? Number(values.dextro) : null,
                peso: values.peso.length > 0 ? values.peso : null,
                altura: values.altura.length > 0 ? values.altura : null,
                temperatura: values.temperatura.length > 0 ? values.temperatura : null,
                saturacao: values.saturacao.length > 0 ? Number(values.saturacao) : null,
                imc: values.imc,
                queixa: values.queixa,
                profissional_id: usuario
            },
            { headers }
        );
        return response;
    },
    createMedicacaoAlegia: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = { Authorization: `Bearer ${token}` };
        const response = await api.post(
            `/medicacao-alergia/${Number(values.ficha)}`,
            {
                medicacao: values?.arrayDeMedicacao,
                alergia: values?.arrayDeAlergia
            },
            { headers }
        );
        return response;
    },
    getMedicacaoAlergiaByPacienteId: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = { Authorization: `Bearer ${token}` };
        const response = await api.get(`/medicacao-alergia/${parseInt(id)}`, { headers });
        return response;
    },
    //===================Relatorios =================================

    getPacientePorPeriodoById: async (pacienteID, date) => {
        const token = JSON.parse(localStorage.getItem('token'));

        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.post(
            `/saidas/paciente-all/${pacienteID?.id}`,
            {
                dataInicio: date?.firstDate,
                dataFinal: date?.lastDate
            },
            { headers }
        );
        return response;
    },
    getAllSaidasByProntuarioId: async (prontuarioID) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.get(`/saidas/prontuario/${prontuarioID}`, {}, { headers });
        return response;
    },

    //========== EXAMES =========
    createExame: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/exames',
            {
                nome: values.nome.toUpperCase(),
                sigla: values.sigla.toUpperCase(),
                material: values.material.toUpperCase(),
                metodo: values.metodo.toUpperCase(),
                campos: values.camposEspecificos
            },
            { headers }
        );
        return response;
    },
    getExameById: async (exameID) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/exames/${exameID}`, { headers });
        return response;
    },
    getExamePacientePorPeriodoById: async (pacienteID, date) => {
        const token = JSON.parse(localStorage.getItem('token'));

        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.post(
            `/exames/exames-pacientes-por-periodo/`,
            {
                dataInicio: date.firstDate,
                dataFinal: date.lastDate,
                paciente_id: pacienteID
            },
            { headers }
        );
        return response;
    },
    getExameEspecialById: async (exameID) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/exames-especiais/${exameID}`, { headers });
        return response;
    },
    getExameAndResultById: async (exameID, prontuarioID, idExame) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/exames/exame-resultados/${exameID}/${prontuarioID}/${idExame}`, {
            headers
        });
        return response;
    },
    getExameEspecialAndResultById: async (exameID, prontuarioID, idExame) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/exames-especiais/exame-resultados/${exameID}/${prontuarioID}/${idExame}`, {
            headers
        });
        return response;
    },
    getAllExames: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/exames', { headers });
        return response;
    },

    getAllExamesAndCamposEspecificosByProntuario: async (prontuarioID) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/exames/prontuario/${prontuarioID}`, { headers });
        return response;
    },
    getExamesForFichaBancada: async (prontuarioId, fichaPayload) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.post(`/exames/prontuario/${prontuarioId}/ficha`, fichaPayload, { headers });
        return response;
    },
    getAllExamesEspeciaisAndCamposEspecificosByProntuario: async (prontuarioID) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/exames-especiais/prontuario/${prontuarioID}`, { headers });
        return response;
    },
    updateExame: async (exameID, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/exames/${exameID}`,
            {
                nome: values.nome.toUpperCase(),
                sigla: values.sigla.toUpperCase(),
                material: values.material.toUpperCase(),
                metodo: values.metodo.toUpperCase(),
                campos: values.campos_especificos
            },
            { headers }
        );
        return response;
    },
    getHoraUnicaLaudo: async (idExameProntuario) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.put('/exame-prontuario/laudo-hora', idExameProntuario, {
            headers: headers
        });
        return response;
    },
    finalizarExame: async (idExameProntuario) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.put(`/exames/finalizar-exame/${idExameProntuario}`, null, {
            headers: headers
        });
        return response;
    },
    verificarStatusExameProntuario: async (idExameProntuario) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.get(`/exames/verificar-exame/${idExameProntuario}`, { headers });
        return response;
    },
    verificarStatusExameEspecialProntuario: async (idExameProntuario) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.get(`/exames-especiais/verificar-exame/${idExameProntuario}`, { headers });
        return response;
    },
    deleteExame: async (exameID) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/exames/${exameID}`, { headers });
        return response;
    },
    getAllExameData: async (prontuarioID, exameID, idExameProntuario) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/resultados/filtred/${prontuarioID}/${exameID}/${idExameProntuario}`, { headers });
        return response;
    },

    createCamposEspecificos: async (idExame, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/campo-especifico',
            {
                id_exame: idExame,
                campo_nome: values.nome_especifico ? values.nome_especifico.toUpperCase() : values.campo_nome.toUpperCase(),
                unidade: values.unidade.toUpperCase(),
                categoria: values.categoria.toUpperCase(),
                faixa_etaria_1: values.faixa_etaria1,
                faixa_etaria_2: values.faixa_etaria2,
                sexo: values.sexo,
                tipo_faixa_etaria: values.tipo,
                valores: values.valores.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    updateCamposEspecificos: async (exameID, campoID, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/campo-especifico/${campoID}`,
            {
                id_exame: exameID,
                campo_nome: values.campo_nome.toUpperCase(),
                unidade: values.unidade.toUpperCase(),
                categoria: values.categoria.toUpperCase(),
                faixa_etaria_1: values.faixa_etaria_1,
                faixa_etaria_2: values.faixa_etaria_2,
                sexo: values.sexo,
                tipo_faixa_etaria: values.tipo_faixa_etaria,
                valores: values.valores.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    deleteCampoEspecifico: async (campoID) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/campo-especifico/${campoID}`, { headers });
        return response;
    },
    examesPorPeriodo: async (dataInicio, dataFinal) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            `/exames/exames-realizados-periodo`,
            {
                dataInicio: dataInicio,
                dataFinal: dataFinal
            },
            { headers }
        );
        return response;
    },

    listaPacientePorExames: async (dataInicio, dataFinal, exame_id, exame_especial_id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        try {
            const response = await api.post(
                `/exames/exames-por-periodo`,
                {
                    dataInicio: dataInicio,
                    dataFinal: dataFinal,
                    exame_id: exame_id,
                    exame_especial_id: exame_especial_id
                },

                { headers }
            );

            return response;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    },
    listaPacientePorExamesTodos: async (dataInicio, dataFinal) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        try {
            const response = await api.post(
                `/exames/exames-por-periodo-todos`,
                {
                    dataInicio: dataInicio,
                    dataFinal: dataFinal
                },

                { headers }
            );

            return response;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    },

    //========== ASSOCIAR EXAME =========
    getAllMedicos: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/usuarios/medicos', { headers });
        return response;
    },

    getMedicoPorId: async (idMedico) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/usuarios/medicos/${idMedico}`, { headers });
        return response;
    },

    associateExameOnProntuario: async (prontuarioID, exameID, medicoID) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/exames/associar-exame-prontuario',
            {
                prontuarioId: prontuarioID,
                exameId: exameID,
                medicoId: medicoID
            },
            { headers }
        );
        return response;
    },
    removeExameFromProntuario: async (exameId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/exames/remover/${exameId}`, { headers });
        return response;
    },
    getAssociatedExame: async (prontuarioID, idExame) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/exames/exame-campo-resultado/${prontuarioID}`, { headers });
        return response;
    },
    getAssociatedExameEspecial: async (prontuarioID, idExame) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/exames-especiais/exame-campo-resultado/${prontuarioID}`, { headers });
        return response;
    },
    //========== RESULTADO EXAME =========
    saveExameResult: async (prontuarioID, idExameProntuario, campos) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.post(
            '/resultados',
            {
                prontuarioId: parseInt(prontuarioID),
                id_exame_prontuario: idExameProntuario,
                campos
            },
            { headers }
        );
        return response;
    },

    getResultsForLaudos: async (value) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post('/resultados/laudos/conjunto', value, { headers });
        return response;
    },
    saveObservation: async (prontuarioID, idExame, idExameProntuario, observacao, observacaoSalva) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/observacao-resultados-exames/',
            {
                prontuario_id: parseInt(prontuarioID),
                exame_id: parseInt(idExame),
                exame_especial_id: null,
                prontuario_exame_id: parseInt(idExameProntuario),
                observacao,
                observacaoSalva
            },
            { headers }
        );
        return response;
    },

    saveObservationEspecial: async (prontuarioID, idExame, idExameProntuario, observacao, observacaoSalva) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/observacao-resultados-exames/observacao/especial',
            {
                prontuario_id: parseInt(prontuarioID),
                exame_id: null,
                exame_especial_id: parseInt(idExame),
                prontuario_exame_id: parseInt(idExameProntuario),
                observacao,
                observacaoSalva
            },
            { headers }
        );
        return response;
    },

    editObservation: async (id, prontuarioID, idExame, idExameProntuario, observacao) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/observacao-resultados-exames/${id}`,
            {
                prontuario_id: parseInt(prontuarioID),
                exame_id: parseInt(idExame),
                exame_especial_id: null,
                prontuario_exame_id: parseInt(idExameProntuario),
                observacao
            },
            { headers }
        );
        return response;
    },
    editObservationEspecial: async (id, prontuarioID, idExame, idExameEspecial, idExameProntuario, observacao) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/observacao-resultados-exames/${id}`,
            {
                prontuario_id: parseInt(prontuarioID),
                exame_id: null,
                exame_especial_id: parseInt(idExameEspecial),
                prontuario_exame_id: parseInt(idExameProntuario),
                observacao
            },
            { headers }
        );
        return response;
    },

    getObservation: async (prontuarioID, idExame, idExameEspecial, idExameProntuario) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            `/observacao-resultados-exames/observation`,
            {
                prontuarioID: prontuarioID,
                idExame: idExame,
                idExameEspecial: idExameEspecial,
                idExameProntuario: idExameProntuario
            },
            {
                headers
            }
        );
        return response;
    },

    desativarExames: async (exameProntuarioID) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/exames/${exameProntuarioID}/inativar`,
            {},
            {
                headers
            }
        );
        return response;
    },
    //========== ALMOXARIFADOS =========
    getAlmoxarifados: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/almoxarifado', { headers });
        return response;
    },
    getAlmoxarifadoById: async (almoxarifadoId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/almoxarifado/${almoxarifadoId}`, { headers });
        return response;
    },
    getProdutoAlmoxarifadoById: async (almoxarifadoId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`mov-sumarizados/relatorio-detalhado/${almoxarifadoId}`, { headers });
        return response;
    },
    getProdutoPsicotropico: async (almoxarifadoId, value) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`mov-sumarizados/relatorio-detalhado/almoxarifado/${almoxarifadoId}?psico=${value}`, { headers });
        return response;
    },
    createAlmoxarifado: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/almoxarifado',
            {
                descricao: values.descricao.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    updateAlmoxarifado: async (almoxarifadoId, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/almoxarifado/${almoxarifadoId}`,
            {
                descricao: values.descricao.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    deleteAlmoxarifado: async (almoxarifadoId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/almoxarifado/${almoxarifadoId}`, { headers });
        return response;
    },

    //========== LOCALIZAÇÕES =========
    createLocalizacao: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            `/localizacao`,
            {
                descricao: values.descricao.toUpperCase(),
                almoxarifado_id: values.almoxarifado_id
            },
            { headers }
        );
        return response;
    },
    getAllLocalizacoes: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/localizacao', { headers });
        return response;
    },
    getLocalizacaoById: async (localizacaoId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/localizacao/${localizacaoId}`, { headers });
        return response;
    },
    updateLocalizacao: async (localizacaoId, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/localizacao/${localizacaoId}`,
            {
                descricao: values.descricao.toUpperCase(),
                almoxarifado_id: values.almoxarifado_id
            },
            { headers }
        );
        return response;
    },
    deleteLocalizacao: async (localizacaoId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/localizacao/${localizacaoId}`, { headers });
        return response;
    },
    //========== CATEGORIA =========
    createCategoria: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/categoria-itens',
            {
                descricao: values.descricao.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    getAllCategorias: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/categoria-itens', { headers });
        return response;
    },
    getCategoriaById: async (categoriaId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/categoria-itens/${categoriaId}`, { headers });
        return response;
    },
    updateCategoria: async (categoriaId, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/categoria-itens/${categoriaId}`,
            {
                descricao: values.descricao.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    deleteCategoria: async (categoriaId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/categoria-itens/${categoriaId}`, { headers });
        return response;
    },
    //========== UNIDADE =========
    createUnidade: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/unidade-itens',
            {
                descricao: values.descricao.toUpperCase(),
                sigla: values.sigla.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    getAllUnidade: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/unidade-itens', { headers });
        return response;
    },
    getUnidadeById: async (unidadeId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/unidade-itens/${unidadeId}`, { headers });
        return response;
    },
    updateUnidade: async (unidadeId, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/unidade-itens/${unidadeId}`,
            {
                descricao: values.descricao.toUpperCase(),
                sigla: values.sigla.toUpperCase()
            },
            { headers }
        );
        return response;
    },
    deleteUnidade: async (unidadeId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/unidade-itens/${unidadeId}`, { headers });
        return response;
    },
    //========== ITEM =========
    createItem: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            `/itens`,
            {
                descricao: values.item.toUpperCase(),
                apelido: values.apelido.toUpperCase(),
                categoria_id: values.categoria_id,
                unidade_id: values.unidade_id,
                quantidade_minima: values.minimo,
                almoxarifado_id: values.almoxarifado_id,
                localizacao_id: values.localizacao_id,
                validade: values.validade,
                lote: values.lote,
                fracionamento: values.fracionamento,
                psicotropico: values.psicotropicos
            },
            { headers }
        );
        return response;
    },
    getAllItems: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/itens', { headers });
        return response;
    },
    getAllItemsNoFilter: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/itens/all/list', { headers });
        return response;
    },
    getAllLoteItens: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/itens/${id}/lote`, { headers });
        return response;
    },
    getItemById: async (itemId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/itens/${itemId}`, { headers });
        return response;
    },

    getFilterItemsByIdAndDate: async (itemId, firstDate, lastDate) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/saidas/all/filtred`, { itemId, firstDate, lastDate }, { headers });
        return response;
    },

    updateItem: async (itemId, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/itens/${itemId}`,
            {
                descricao: values?.item.toUpperCase(),
                apelido: values?.apelido.toUpperCase(),
                categoria_id: values?.categoria_id,
                unidade_id: values?.unidade_id,
                quantidade_minima: values?.minimo,
                almoxarifado_id: values?.almoxarifado_id,
                localizacao_id: values?.localizacao_id,
                validade: values?.validade,
                lote: values?.lote,
                fracionamento: values?.fracionamento,
                psicotropico: values?.psicotropicos,
                ativo: values?.ativo
            },
            { headers }
        );
        return response;
    },
    deleteItem: async (itemId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/itens/${itemId}`, { headers });
        return response;
    },

    //========== FORNECEDORES =========
    createFornecedor: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/fornecedores',
            {
                cnpj: values.cnpj,
                nome_fantasia: values.nome_fantasia.toUpperCase(),
                email: values.email.toUpperCase(),
                contato: values.contato.toUpperCase(),
                telefone_comercial: values.telefone_comercial,
                telefone_celular: values.telefone_comercial,
                cep: values.cep,
                endereco: values.endereco.toUpperCase(),
                numero: values.numero,
                complemento: values.complemento.toUpperCase(),
                bairro: values.bairro.toUpperCase(),
                cidade: values.cidade.toUpperCase(),
                estado: values.estado,
                observacao: values.observacao.toUpperCase(),
                status: values.ativo
            },
            { headers }
        );
        return response;
    },
    getAllFornecedor: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/fornecedores', { headers });
        return response;
    },
    getFornecedorById: async (fornecedorId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/fornecedores/${fornecedorId}`, { headers });
        return response;
    },
    updateFornecedor: async (fornecedorId, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/fornecedores/${fornecedorId}`,
            {
                cnpj: values.cnpj,
                nome_fantasia: values.nome_fantasia.toUpperCase(),
                email: values.email.toUpperCase(),
                contato: values.contato.toUpperCase(),
                telefone_comercial: values.telefone_comercial,
                telefone_celular: values.telefone_comercial,
                cep: values.cep,
                endereco: values.endereco.toUpperCase(),
                numero: values.numero,
                complemento: values.complemento.toUpperCase(),
                bairro: values.bairro.toUpperCase(),
                cidade: values.cidade.toUpperCase(),
                estado: values.estado,
                observacao: values.observacao.toUpperCase(),
                status: values.ativo
            },
            { headers }
        );
        return response;
    },
    deleteFornecedor: async (fornecedorId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/fornecedores/${fornecedorId}`, { headers });
        return response;
    },
    createCentroCusto: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post('/centro-custo', { descricao: values.descricao.toUpperCase() }, { headers });
        return response;
    },
    getAllCentroCusto: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/centro-custo', { headers });
        return response;
    },
    getCentroCustoById: async (centoId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/centro-custo/${centoId}`);
    },
    updateCentroCusto: async (centroId, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/centro-custo/${centroId}`, { descricao: values.descricao.toUpperCase() }, { headers });
        return response;
    },
    deleteCentroCusto: async (centroId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/centro-custo/${centroId}`, { headers });
        return response;
    },
    ////// ENTRADAS ////////
    createEntradaEstoque: async (entrada, somaValorTotal, itensEntrada) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/entradas',
            {
                numero_documento: entrada.documento,
                data_emissao: entrada.emissao,
                data_entrada: entrada.movimento,
                observacao: entrada.observacao,
                fornecedor_id: parseInt(entrada.fornecedor),
                itens: itensEntrada,
                responsavel_id: usuario,
                valor_total_nota: somaValorTotal
            },
            { headers }
        );
        return response;
    },
    getAllEntradasEstoques: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/entradas', { headers });
        return response;
    },
    getEntradaEstoqueById: async (entradaId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/entradas/${entradaId}`, { headers });
        return response;
    },
    updateEntradaEstoque: async (entradaId, entrada, itensEntrada) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/entradas/${entradaId}`,
            {
                numero_documento: entrada.documento,
                data_emissao: entrada.emissao,
                data_entrada: entrada.movimento,
                observacao: entrada.observacao,
                fornecedor_id: entrada.fornecedor,
                itens: itensEntrada,
                responsavel_id: usuario
            },
            { headers }
        );
        return response;
    },
    deleteEntradaEstoque: async (entradaId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/entradas/${entradaId}`, { headers });
        return response;
    },
    ///// SAIDAS /////
    createSaida: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/saidas',
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                quantidade: Number(values.quantidade),
                data: values.data,
                almoxarifado_id: values.almoxarifado,
                localizacao_id: values.localizacao,
                observacao: values.observacao,
                prontuario_id: values.pacienteId ? Number(values.pacienteId) : null,
                centro_id: values.centroId,
                lote_id: values.loteId !== '' ? values.loteId : null,
                medico_id: values.medicoId !== '' ? values.medicoId : null,
                responsavel_id: usuario
            },
            { headers }
        );
        return response;
    },
    createSaidaArray: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/saidas/ficha',
            {
                centroId: values.baixa.centroId,
                medicoId: values.baixa.medicoId,
                fichaId: values.baixa.fichaId,
                data: values.baixa.movimento,
                itens: values.itens,
                responsavel_id: usuario
            },
            { headers }
        );
        return response;
    },
    updateSaida: async (id, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/saidas/${id}`,
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                quantidade: Number(values.quantidade),
                data: values.data,
                almoxarifado_id: values.almoxarifado,
                localizacao_id: values.localizacao,
                observacao: values.observacao,
                paciente_id: values.pacienteId,
                centro_id: values.centroId,
                medico_id: values.medicoId,
                responsavel_id: usuario,
                lote_id: values.loteId
            },
            { headers }
        );
        return response;
    },
    getAllSaidas: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/saidas', { headers });
        return response;
    },
    getSaidaById: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/saidas/${id}`, { headers });
        return response;
    },
    createEstorno: async (data) => {
        const usuario = localStorage.getItem('id');
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post('/extorno', { responsavel_id: parseInt(usuario), saidas: data });
    },
    deleteSaida: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/saidas/${id}`, { headers });
        return response;
    },
    createEntradaManual: async (values, idCentro) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/entrada-manual',
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                quantidade: Number(values.quantidade),
                data: values.data,
                almoxarifado_id: values.almoxarifado,
                localizacao_id: values.localizacao,
                observacao: values.observacao,
                responsavel_id: usuario,
                centro_id: idCentro,
                lote: values.lote
            },
            { headers }
        );
        return response;
    },
    getAllEntradaManual: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/entrada-manual', { headers });
        return response;
    },
    updateEntradaManual: async (id, values, centroId) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/entrada-manual/${id}`,
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                quantidade: Number(values.quantidade),
                data: values.data,
                almoxarifado_id: values.almoxarifado,
                localizacao_id: values.localizacao,
                observacao: values.observacao,
                responsavel_id: usuario,
                centro_id: centroId,
                lote: values.lote
            },
            { headers }
        );
        return response;
    },
    deleteEntradaManual: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/entrada-manual/${id}`, { headers });
        return response;
    },
    createTransferencia: async (values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            '/transferencia-estoque',
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                data_transferencia: values.data,
                quantidade: Number(values.quantidade),
                origem_almoxarifado_id: values.almoxarifado_origem,
                origem_localizacao_id: values.localizacao_origem,
                destino_almoxarifado_id: values.almoxarifado_destino,
                destino_localizacao_id: values.localizacao_destino,
                observacao: values.observacao,
                responsavel_id: usuario,
                origem_lote: values.lote_origem.length > 0 ? values.lote_origem : null
            },
            { headers }
        );
        return response;
    },
    getAllTransferencia: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/transferencia-estoque', { headers });
        return response;
    },
    getTransferenciaById: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/transferencia-estoque/${id}`, { headers });
        return response;
    },
    updateTransferencia: async (id, values) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const usuario = localStorage.getItem('id');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/transferencia-estoque/${id}`,
            {
                item_id: values.itemId,
                unidade_id: values.unidade,
                data_transferencia: values.data,
                quantidade: Number(values.quantidade),
                origem_almoxarifado_id: values.almoxarifado_origem,
                origem_localizacao_id: values.localizacao_origem,
                origem_lote: values.origem_lote,
                destino_almoxarifado_id: values.almoxarifado_destino,
                destino_localizacao_id: values.localizacao_destino,
                observacao: values.observacao,
                responsavel_id: usuario,
                origem_lote: values.lote_origem.length > 0 ? values.lote_origem : null
            },
            { headers }
        );
        return response;
    },
    //kits

    createKits: async (value) => {
        const token = JSON.parse(localStorage.getItem('token'));

        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.post(
            '/kit',
            {
                descricao: value.descricao,
                apelido: value.apelido,
                itens: value.itens
            },
            { headers }
        );
    },
    getKits: async () => {
        const token = JSON.parse(localStorage.getItem('token'));

        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await api.get(
            '/kit',

            { headers }
        );
        return response;
    },
    getKitById: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/kit/${id}`, { headers });
        return response;
    },
    editKitById: async (id, value, itens) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/kit/${id}`,
            {
                descricao: value.descricao,
                apelido: value.apelido,
                itens: itens
            },
            { headers }
        );
    },
    deleteKitById: async (id, value, itens) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(
            `/kit-item/${id}`,

            { headers }
        );
        return response;
    },
    deleteKit: async (id, value, itens) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(
            `/kit/${id}`,

            { headers }
        );
        return response;
    },
    getUltimoIdKitItem: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(
            `/kit-item/consulta/ultimo-id`,

            { headers }
        );
        return response;
    },
    // MOV SUMARIZADO
    getMovSumarizados: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/mov-sumarizados', { headers });
        return response;
    },
    getPsicotropicos: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/mov-sumarizados/todos/psicotropicos`, { headers });
        return response;
    },
    getAbertos: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/mov-sumarizados/todos/abertos`, { headers });
        return response;
    },
    getMovSumarizadosByProductId: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/mov-sumarizados/${id}`, { headers });
        return response;
    },
    getMovSumarizadosByProductIdAndPeriod: async (id, data) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/mov-sumarizados/${id}/filter?firstDate=${data.firstDate}&lastDate=${data.lastDate}`, { headers });
        return response;
    },
    getLoteByItemId: async (data) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/mov-sumarizados/${data?.id}/${data?.almoxarifado_id}/${data?.localizacao_id}/item`, { headers });
        return response;
    },
    // KARDEX
    getKardex: async (itemId, dataInicial, dataFinal) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/kardex/${itemId}?dataInicial=${dataInicial}&dataFinal=${dataFinal}`, { headers });
        return response;
    },
    getAllMoves: async (tipo, dataInicial, dataFinal) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/kardex/all/movimentos/?tipo=${tipo}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`, {
            headers
        });
        return response;
    },
    // CHAMDAS
    createChamadaPainel: async (payloadChamada) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            `/chamadas`,
            { setor: payloadChamada.setor, prontuario_id: payloadChamada.prontuario_id },
            {
                headers
            }
        );
        return response;
    },

    getChamadasByProntuarios: async (prontuarios) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/chamadas/prontuarios`, prontuarios, {
            headers
        });
        return response;
    },
    getTotalAtendimentosDia: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/prontuarios/total-atendimentos`, {
            headers
        });
        return response;
    },
    getTotalPEDia: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/prontuarios/total-externos`, {
            headers
        });
        return response;
    },
    // AZURE
    getAzureAccessKey: async (azureRegion, azureKey) => {
        const response = await axios.post(
            `https://${azureRegion}.api.cognitive.microsoft.com/sts/v1.0/issuetoken`,
            {},
            {
                headers: {
                    'Ocp-Apim-Subscription-Key': azureKey
                }
            }
        );

        return response;
    },
    // Raio X
    createXRay: async (payload) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/raiox`, payload, {
            headers
        });
        return response;
    },
    updateXRay: async (id, payload) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/raiox/${id}`, payload, {
            headers
        });
        return response;
    },
    getAllXRay: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/raiox`, {
            headers
        });
        return response;
    },
    deleteXray: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/raiox/${id}`, {
            headers
        });
        return response;
    },
    // GRUPOS RAIOX
    createGrupoRaioX: async (data) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post('/grupos-raiox', { descricao: data.toUpperCase() }, { headers });
        return response;
    },
    getAllGruposRaioX: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/grupos-raiox', { headers });
        return response;
    },
    getGroupById: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/raiox/filtro-grupo/${id}`, { headers });
        return response;
    },
    deleteGrupoRaioX: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/grupos-raiox/${id}`, { headers });
        return response;
    },
    updateGrupoRaioX: async (id, data) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/grupos-raiox/${id}`, { descricao: data.toUpperCase() }, { headers });
        return response;
    },
    getGrupoRaioXById: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/grupos-raiox/${id}`, {}, { headers });
        return response;
    },
    // RAIOX X
    linkExamXray: async (prontuarioId, tipoRaiox) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            `/exame-raiox/${prontuarioId}/${tipoRaiox}`,
            {},
            {
                headers
            }
        );
        return response;
    },
    getLinkExamXray: async (prontuarioId, tipoRaiox) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/exame-raiox/${prontuarioId}`, {
            headers
        });
        return response;
    },
    finishLinkExamXray: async (payload) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/exame-raiox/finalizar`, payload, {
            headers
        });
        return response;
    },
    getAllClosedExamesRaiox: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/exame-raiox/conduta/pedidos-fechados`, {
            headers
        });
        return response;
    },
    getAllClosedExamesRaioxByProntuarioId: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/exame-raiox/conduta/pedidos-fechados/${id}`, {
            headers
        });
        return response;
    },
    // Tipo Procedimentos
    createTipoProcedimento: async (data) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            `/tipo-procedimento`,
            { descricao: data.toUpperCase() },
            {
                headers
            }
        );
        return response;
    },
    updateTipoProcedimento: async (id, data) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/tipo-procedimento/${id}`,
            { descricao: data.toUpperCase() },
            {
                headers
            }
        );
        return response;
    },
    getAllTipoProcedimento: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/tipo-procedimento', { headers });
        return response;
    },
    deleteTipoProcedimento: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/tipo-procedimento/${id}`, { headers });
        return response;
    },
    // Procedimentos
    getAllProcedimentos: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/procedimentos', { headers });
        return response;
    },
    deleteProcedimento: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/procedimentos/${id}`, { headers });
        return response;
    },
    createProcedimento: async (data) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(
            `/procedimentos`,
            { descricao: data.descricao.toUpperCase(), codigo: data.codigo, tipo_id: data.tipo_id },
            { headers }
        );
        return response;
    },
    updateProcedimento: async (id, data) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/procedimentos/${id}`,
            { descricao: data.descricao.toUpperCase(), codigo: data.codigo, tipo_id: data.tipo_id },
            { headers }
        );
        return response;
    },
    // controle procedimentos
    getLinkedExames: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/controle-procedimentos/${id}`, { headers });
        return response;
    },
    postLinkExams: async (examId, id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headersToken = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/controle-procedimentos/${examId}/${id}`, {}, { headers });
        return response;
    },
    finishLinkedExams: async (payload) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/controle-procedimentos/finalizar`, payload, { headers });
        return response;
    },
    getProcedimentosEcgAertos: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/controle-procedimentos/pedidos-abertos/ecg`, { headers });
        return response;
    },
    getProcedimentosEcgFehados: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/controle-procedimentos/pedidos-fechados/ecg`, { headers });
        return response;
    },
    getProcedimentosEcgFehadosByProntuarioId: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/controle-procedimentos/pedidos-fechados/ecg/${id}`, { headers });
        return response;
    },
    //consultório
    AddClinic: async (payload) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/consultorios`, payload, { headers });
        return response;
    },
    getAllClinic: async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/consultorios`, { headers });
        return response;
    },
    deleteClinic: async (id) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/consultorios/${id}`, { headers });
        return response;
    },
    editClinic: async (id, payload) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/consultorios/${id}`, payload, { headers });
        return response;
    },
    //consultas
    linkdoctor: async (payload) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/atendimento-consultorio`, payload, { headers });
        return response;
    },
    getPatientsByClinic: async () => {
        const response = await api.get(`/prontuarios/atendimentos-consultorio`, { headers });
        return response;
    },
    getMedicalRecord: async (prontuarioId, pacienteId) => {
        const response = await api.get(`/triagem/${prontuarioId}/${pacienteId}`, { headers });
        return response;
    },
    addMedicalRecord: async (prontuarioId, payload) => {
        const response = await api.post(`/ficha-medica/${prontuarioId}`, payload, { headers });
        return response;
    },
    getMedicalRecordObservation: async (prontuarioId) => {
        const response = await api.get(`/ficha-medica/${prontuarioId}`, { headers });
        return response;
    },
    //conduta médica
    getMedicalConduct: async (prontuarioId) => {
        const response = await api.get(`/conduta-medica/prontuario/${prontuarioId}`, { headers });
        return response;
    },
    linkExamLaboratory: async (payload) => {
        const response = await api.post(`/conduta-medica/pedido-laboratorio`, payload, { headers });
        return response;
    },
    linkRayxConduct: async (payload) => {
        const response = await api.post(`/conduta-medica/pedido-raiox`, payload, { headers });
        return response;
    },
    linkEcgConduct: async (payload) => {
        const response = await api.post(`/conduta-medica/pedido-procedimento`, payload, { headers });
        return response;
    },
    linkMedicinesConduct: async (payload) => {
        const response = await api.post(`/pedido-medicacao`, payload, { headers });
        return response;
    },
    linkProntuarioToPatient: async (payload) => {
        const response = await api.post(`/atendimento-consultorio/vincular-prontuario`, payload, { headers });
        return response;
    },
    deleteConductLab: async (id) => {
        const response = await api.delete(`/conduta-medica/delete/pedido-laboratorio/${id}`, { headers });
        return response;
    },
    deleteConductRayx: async (id) => {
        const response = await api.delete(`/conduta-medica/delete/pedido-raiox/${id}`, { headers });
        return response;
    },
    deleteConductMedicine: async (id) => {
        const response = await api.delete(`/conduta-medica/delete/pedido-medicacao/${id}`, { headers });
        return response;
    },
    deleteConductEcg: async (id) => {
        const response = await api.delete(`/conduta-medica/delete/pedido-procedimento/${id}`, { headers });
        return response;
    },
    // update da contuda
    updateConductProcedimento: async (id, payload) => {
        const response = await api.put(`/conduta-medica/pedido-procedimento/${id}`, payload, { headers });
        return response;
    },
    updateConductRayx: async (id, payload) => {
        const response = await api.put(`/conduta-medica/pedido-raiox/${id}`, payload, { headers });
        return response;
    },
    updateConductExam: async (id, payload) => {
        const response = await api.put(`/conduta-medica/pedido-laboratorio/${id}`, payload, { headers });
        return response;
    },
    // Tela Raiox
    getAllOpenExamsRaiox: async () => {
        const response = await api.get(`/exame-raiox/conduta/pedidos-abertos`, { headers });
        return response;
    },
    getAllPedidosMedicamentoAbertos: async () => {
        const response = await api.get(`/pedido-medicacao`, { headers });
        return response;
    },
    getAllPedidosMedicamentoFinalizados: async () => {
        const response = await api.get(`/pedido-medicacao/itens-atendidos`, { headers });
        return response;
    },
    getAllItensPedidosMedicamentoByProntuario: async (id) => {
        const response = await api.get(`/pedido-medicacao/${id}`, {}, { headers });
        return response;
    },
    getAllPedidosMedicamentoFinalizadosByProntuario: async (id) => {
        const response = await api.get(`/pedido-medicacao/${id}/finalizados`, {}, { headers });
        return response;
    },
    // BAIXA ITENS CONDUTA MEDICA
    baixaItemUnicoConduta: async (data) => {
        const response = await api.post(
            `/saidas/conduta-medica`,
            {
                responsavel_id: data.responsavelId,
                item_id: data.item_id,
                unidade_id: data.unidade_id,
                quantidade: data.quantidade,
                almoxarifado_id: data.almoxarifado_id,
                localizacao_id: data.localizacao_id,
                observacao: data.observacao,
                prontuario_id: data.prontuario_id,
                centro_id: data.centro_id,
                lote_id: data.lote_id,
                medico_id: data.medico_id,
                conduta_id: data.conduta_id,
                item_novo: data.item_novo
            },
            { headers }
        );
        return response;
    },
    baixaConjuntoItensConduta: async (payload) => {
        const response = await api.post(`/saidas/conduta-medica/grupo`, { itens: payload }, { headers });
        return response;
    },
    deletarMedicamentoDaConduta: async (payload) => {
        const response = await api.put(`/conduta-medica/delete/pedido-medicacao`, payload, { headers });
        return response;
    },
    // NOVO LAB
    getAllPendindExamsLab: async () => {
        const response = await api.get('/laboratorio/exames-abertos', { headers });
        return response;
    },
    getAllPendindExamsLabByProntuarioId: async (id) => {
        const response = await api.get(`/laboratorio/exames-abertos/${id}`, { headers });
        return response;
    },
    getAllClosedExamsLab: async (id) => {
        const response = await api.get(`/laboratorio/exames-fechados`, { headers });
        return response;
    },
    getAllClosedExamsLabByProntuarioId: async (id) => {
        const response = await api.get(`/laboratorio/exames-fechados/${id}`, { headers });
        return response;
    },
    // ATESTADO
    createAtestado: async (data) => {
        const usuario = localStorage.getItem('id');
        const response = await api.post(
            `/atestado`,
            {
                dias: data.dias,
                responsavel_id: usuario,
                paciente_id: data.pacienteId,
                prontuario_id: data.prontuarioId,
                observacao: data.observacao,
                assinatura_id: data.assinatura_id
            },
            { headers }
        );
        return response;
    },
    getAtestadosByProntuarioId: async (prontuarioId) => {
        const response = await api.get(`/atestado/${prontuarioId}`, { headers });
        return response;
    },
    getAtestadoReceitaByProntuario: async (prontuarioId) => {
        const response = await api.get(`/atestado/conjunto/receita/${prontuarioId}`, { headers });
        return response;
    },
    getAtestadoById: async (id) => {
        const response = await api.get(`/atestado/unico/${id}`, { headers });
        return response;
    },
    // RECEITA
    createReceita: async (payload) => {
        const response = await api.post(`/receita`, payload, { headers });
        return response;
    },
    getReceitasByProntuarioId: async (prontuarioId) => {
        const response = await api.get(`/receita/${prontuarioId}`, { headers });
        return response;
    },
    getReceitaById: async (id) => {
        const response = await api.get(`/receita/unico/${id}`, { headers });
        return response;
    },
    // Finalizar conduta
    finalizarConduta: async (id, data) => {
        const response = await api.put(
            `/prontuarios/${id}/finalizar-atendimento`,
            {
                alta: data.alta,
                retorno_medicacao_exame: data.retorno_medicacao_exame,
                alta_medicacao_procedimento_cuidado: data.alta_medicacao_procedimento_cuidado,
                encaminhar_internacao: data.encaminhar_internacao,
                observacao: data.observacao
            },
            { headers }
        );
        return response;
    },
    // ASSINATURA
    createMedicoAssinatura: async (data) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        };
        const response = await api.post(`/assinatura`, data, { headers });
        return response;
    },
    createMedicoAssinaturaInEditUser: async (id, data) => {
        const token = JSON.parse(localStorage.getItem('token'));
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        };
        const response = await api.post(`/assinatura/vincular/${id}`, data, { headers });
        return response;
    },
    getSignature: async (id) => {
        const response = await api.get(`/assinatura/${id}`, { headers });
        return response;
    },
    confirmSignature: async (payload) => {
        const response = await api.post(`/assinatura/validar`, payload, { headers });
        return response;
    },
    createRoom: async (data) => {
        const response = await api.post(`/sala-enfermagem`, data, { headers });
        return response;
    },
    getRooms: async () => {
        const response = await api.get(`/sala-enfermagem`, { headers });
        return response;
    },
    updateRoom: async (id, data) => {
        const response = await api.put(`/sala-enfermagem/${id}`, data, { headers });
        return response;
    },
    deleteRoom: async (id) => {
        const response = await api.delete(`/sala-enfermagem/${id}`, { headers });
        return response;
    },
    getAllNurse: async () => {
        const response = await api.get(`/enfermagem`, { headers });
        return response;
    },
    createNurseReport: async (data) => {
        const response = await api.post(`/relatorio-acompanhamento`, data, { headers });
        return response;
    },
    getAllNurseReport: async (prontuarioId) => {
        const response = await api.get(`/relatorio-acompanhamento/${prontuarioId}`, { headers });
        return response;
    },
    deleteNurseReport: async (id) => {
        const response = await api.delete(`/relatorio-acompanhamento/${id}`, { headers });
        return response;
    },
    updateNurseReport: async (id, data) => {
        const response = await api.put(`/relatorio-acompanhamento/${id}`, data, { headers });
        return response;
    },
    updateMedicationReceived: async (payload) => {
        const response = await api.put(`/enfermagem/status`, payload, { headers });
        return response;
    },
    getAllPendentesByProntuario: async (prontuarioId) => {
        const response = await api.get(`/enfermagem/${prontuarioId}`, { headers });
        return response;
    },
    updateMedicationFinished: async (data) => {
        const response = await api.put(`/enfermagem/finalizar-prontuario-medicacao`, data, { headers });
        return response;
    },
    updateLaboratoryFinished: async (data) => {
        const response = await api.put(`/enfermagem/finalizar-prontuario-laboratorio`, data, { headers });
        return response;
    },
    linkRoomToProntuario: async (prontuarioId, salaId) => {
        const response = await api.put(`/sala-enfermagem/vincular/${prontuarioId}/${salaId}`, {}, { headers });
        return response;
    },
    getMedicacaoPendentePainel: async () => {
        const response = await api.get('/enfermagem/painel/medicacao', {}, { headers });
        return response;
    },
    getLaboratorioPendentePainel: async () => {
        const response = await api.get('/enfermagem/painel/laboratorio', {}, { headers });
        return response;
    },
    // XML
    sendXML: async (xmlFile) => {
        const response = await api.post('/xml', xmlFile, { headers });
        return response;
    }
});
